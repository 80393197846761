<template>
  <base-view>
    <template #header>
      <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
        <div class="container">
          <div class="page-header-content pt-4">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto mt-4">
                <h1 class="page-header-title">
                  <div class="page-header-icon"><i data-feather="arrow-right-circle"></i></div>
                  Ampliación y reducción
                </h1>
                <div class="page-header-subtitle">
                  Importación de layout para control de ampliación y reducción.
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </template>

    <div class="container-fluid mt-n10">
      <div class="card">
        <div class="card-header border-bottom">
          <!-- Wizard navigation-->
          <div
            class="nav nav-pills nav-justified flex-column flex-xl-row nav-wizard"
            role="tablist"
          >
            <a
              v-for="tab in tabs"
              :key="tab.id"
              class="nav-item nav-link disabled"
              :class="selected.name === tab.name ? 'active' : ''"
            >
              <div class="wizard-step-icon">{{ tab.id }}</div>
              <div class="wizard-step-text">
                <div class="wizard-step-text-name">{{ tab.label }}</div>
                <div class="wizard-step-text-details">{{ tab.description }}</div>
              </div>
            </a>
          </div>
        </div>

        <div class="card-body">
          <div class="tab-content" id="cardTabContent">
            <!-- Importación -->
            <div
              class="tab-pane py-3 fade show active"
              role="tabpanel"
              v-if="selected.name === 'importacion'"
            >
              <div class="row justify-content-center">
                <div class="col-xxl-6 col-xl-8">
                  <h3 class="text-primary">Importación</h3>
                  <h6 class="card-title">
                    Selecciona el archivo layout para su procesamiento.
                  </h6>
                  <import namespaces="ampliacionReduccionModule"></import>
                  <div class="pt-5 d-flex justify-content-end">
                    <button
                      class="btn btn-primary"
                      @click="goToEdicion"
                      :disabled="!importacion.processed"
                    >
                      Editar información
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Proyección -->
            <div
              v-if="selected.name === 'edicion'"
              class="tab-pane py-3 fade show active"
              role="tabpanel"
            >
              <div class="row justify-content-center" style="min-height: 50px">
                <div class="col">
                  <h3 class="text-primary">Edición</h3>
                  <h6 class="my-3">Consulta y modifica la información importada.</h6>

                  <b-overlay :show="loading">
                    <template #overlay>
                      <div class="text-center">
                        <loading
                          message="Ejecutando algoritmo para la separación de importes, por favor espere..."
                        ></loading>
                      </div>
                    </template>
                    <edicion namespaces="ampliacionReduccionModule"></edicion>
                  </b-overlay>

                  <div class="d-flex justify-content-between">
                    <button class="btn btn-light" @click="backToImportes" :disabled="loading">
                      Anterior
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-view>
</template>

<script>
import { mapState } from 'vuex'

import Import from '@/components/FederalYAmpliacion/Import'
import Edicion from '@/components/FederalYAmpliacion/Edicion'

export default {
  name: 'AmpliacionReduccionImportView',

  components: {
    Import, Edicion
  },

  props: {
    tabIndex: {
      type: Number,
      required: true,
      default: 0
    }
  },

  created () {
    if (!this.selected) {
      this.$store.commit('ampliacionReduccionModule/setTab', this.tabs[0])
    }
  },

  data () {
    return {
      loading: false,

      tabs: [
        {
          id: 1,
          name: 'importacion',
          label: 'Importación',
          description: 'Importa el layout'
        },
        {
          id: 2,
          name: 'edicion',
          label: 'Edición',
          description: 'Consulta y modifica la información importada'
        }
      ]
    }
  },

  computed: {
    ...mapState('ampliacionReduccionModule', ['selected', 'importacion', 'recursos'])
  },

  methods: {
    goToEdicion () {
      this.$store.commit('ampliacionReduccionModule/setTab', this.tabs[1])
    },

    backToImportes () {
      this.$store.commit('ampliacionReduccionModule/setTab', this.tabs[0])
    }
  }
}
</script>
